import React, { useState, useEffect } from 'react';
import './App.css';
import SpotifyConnectImage from './images/SpotifyConnect.png'
import { type } from '@testing-library/user-event/dist/type';


const track = { //creates variable for storing the current track
    name: "",
    album: {
        images: [
            { url: "" }
        ]
    },
    artists: [
        { name: "" }
    ]
}

function WebPlayback(props) {

    const [is_paused, setPaused] = useState(false);
    const [is_active, setActive] = useState(false);
    const [player, setPlayer] = useState(undefined);
    const [current_track, setTrack] = useState(track);
    const [tabsContent, setTabsContent] = useState();
    const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(false);


    const fetchTabs = async (current_track) => { //function to request tabs through the proxy
        try {
            const apiURL = 'http://localhost:5000/api/data';
            const response = await fetch(apiURL,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },                              
                body: JSON.stringify({trackName: current_track.name, artist: current_track.artists[0].name, type: document.getElementById('type').value})
            });                             //parses track name, artist and type (chord/tab)
            const data = await response.text();
            setTabsContent(data); //sets the tabDisplay content to the tab from freetar
            document.getElementById('transpose').innerHTML=0;//reset the transpose
            //document.getElementById('tabDisplay').scrollBy(0, -1000);//scroll to top of tab
        } catch (error) {
            console.error('Error during fetch:', error);
        }
    };


    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://sdk.scdn.co/spotify-player.js"; //embeds sdk
        script.async = true;

        document.body.appendChild(script);

        window.onSpotifyWebPlaybackSDKReady = () => { //automatically called once the Web Playback SDK has successfully loaded

            const player = new window.Spotify.Player({ //creates player object
                name: 'RiffReady',
                getOAuthToken: cb => { cb(props.token); }, //callback expected to provide a valid access token
                volume: 0.5 //sets volume to half
            });

            setPlayer(player); 

            player.addListener('ready', ({ device_id }) => { 
                console.log('Ready with Device ID', device_id);
            });

            player.addListener('not_ready', ({ device_id }) => {
                console.log('Device ID has gone offline', device_id);
            });

            player.addListener('player_state_changed', ( state => { //if state changes

                if (!state) {
                    return;
                }

                setTrack(state.track_window.current_track); //sets track variable ot current track
                fetchTabs(state.track_window.current_track); //fetches tab for new song
                setPaused(state.paused); //sets pause state to current state

                player.getCurrentState().then( state => { 
                    (!state)? setActive(false) : setActive(true) //if there isnt a state set active to false else set it to true
                });



            }));

            player.connect(); //connects player to spotify connect

        };
    },[]);

    const toggleAutoScroll = () => {
        setIsAutoScrollEnabled(prevState => !prevState); //toggles the autoscroll setting
      };

    const transposeChords = (step) => {
        document.getElementById('transpose').innerHTML=parseInt(document.getElementById('transpose').innerHTML)+step; //adds/minuses 1 from the transpose label based on which button is pressed
        const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']; //list of all notes
        var chordsArray = document.getElementsByClassName("chord-root"); //gets all the chords in the tabsDisplay

        for(var i = (chordsArray.length - 1); i >= 0; i--) //iterates through the array
        {
            const chordIndex = notes.indexOf(chordsArray[i].innerHTML); //gets the index of the chord
            let transposedChordIndex = (chordIndex+step); //changes to the chord above/below based on which button has been pressed
            if (transposedChordIndex === -1){ //if moved past bottom of the list then move to the top
                transposedChordIndex = 11;
            }
            if (transposedChordIndex === 12){ //if moved past the top then move to the bottom of the list
                transposedChordIndex = 0;
            }
            if (notes[transposedChordIndex]){ 
            chordsArray[i].innerHTML = notes[transposedChordIndex]; //sets the chord to the new transposed chord
            }
        }
    }

    useEffect(() => {
        let animationFrameId;
        let lastTimestamp = null;    
    
        const scrollWhilePlaying = (timestamp) => {
            if (!lastTimestamp) {
                lastTimestamp = timestamp;
            }
    
            const deltaTime = timestamp - lastTimestamp; //calculates the time passed since the last change to scroll
            
            if (deltaTime > 80 && !is_paused && isAutoScrollEnabled) { //if enough time has passed and the song is playing and autoscroll enabled
                const tabDisplayElement = document.getElementById("tabDisplay"); // 
                if (tabDisplayElement) {
                    tabDisplayElement.scrollBy(0, 1);
                }
    
                lastTimestamp = timestamp; //change to current timestamp
    
            }
            animationFrameId = requestAnimationFrame(scrollWhilePlaying);
        };
    
        if (!is_paused && isAutoScrollEnabled) {
            // Start scrolling when the song is playing
            animationFrameId = requestAnimationFrame(scrollWhilePlaying);
        }
    
        // Cleanup on component unmount or when the song is paused
        return () => {
            cancelAnimationFrame(animationFrameId);
            lastTimestamp = null;
        };
    }, [is_paused, isAutoScrollEnabled]);
    
    
    if (!is_active) {  //if no player active need to transfer playback
        return (
            <div className='App-header'>
                <div className="container" >
                    <div className="main-wrapper" style={{ textAlign: 'center' }}>
                        <b>Instance not active. Transfer your playback using the speaker icon in your Spotify app</b>
                        <br />
                        <img src={SpotifyConnectImage} alt='Spotify Connect' style={{ width: '20%', height: 'auto', display: 'block', margin: '0 auto' }} />
                    </div>
                </div>
            </div>)
    } else { //if player active
        return (
            <div className='WebPlayback' style={{height: window.innerHeight}}>
                <div className="container" style={{ display: 'flex' }}> 
                    <div className="main-wrapper">

                        <img src={current_track.album.images[0].url} className="now-playing__cover" alt="" />

                        <div className="now-playing__side">
                            <div className="now-playing__name">{current_track.name}</div>
                            <div className="now-playing__artist">{current_track.artists[0].name}</div>

                            <button className="btn-spotify" onClick={() => { player.previousTrack(); if (document.getElementById('tabDisplay')){document.getElementById('tabDisplay').scrollBy(0, -1000);} }} >
                                &lt;&lt;
                            </button>

                            <button className="btn-spotify" onClick={() => { player.togglePlay() }} >
                                { is_paused ? "PLAY" : "PAUSE" }
                            </button>

                            <button className="btn-spotify" onClick={() => { player.nextTrack(); if (document.getElementById('tabDisplay')){document.getElementById('tabDisplay').scrollBy(0, -1000);} }} >
                                &gt;&gt;
                            </button>
                        </div>
                        <label>
                                Auto Scroll
                                <input
                                    type="checkbox"
                                    checked={isAutoScrollEnabled}
                                    onChange={toggleAutoScroll}
                                />
                            </label>
                            <label>
                                Type:
                                <select name="type" id="type" onChange={() => {fetchTabs(current_track)}}>
                                <option value="tabs">Tab</option>
                                <option value="chords">Chord</option>
                                </select>
                            </label>
                            <label>
                                Transpose
                                <button onClick={() => {transposeChords(-1)}}>-</button>
                                <label id='transpose'>0</label>
                                <button onClick={() => {transposeChords(1)}}>+</button>
                            </label>
                    </div>
                {(tabsContent != '{"error":"Internal Server Error","details":"No tab available"}') ? <div id="tabDisplay" className='Tab' style={{maxHeight:window.innerHeight}} dangerouslySetInnerHTML={{__html: tabsContent}}/> : <div className='noTab'>No tab available for current song</div>}
                </div>    
                
            </div>
        );
    }
}

export default WebPlayback
